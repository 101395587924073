@import "./media.scss";

.banner {
    padding: 20px 10px 70px !important;
      background-size: cover;
  .container__ {
    max-width: 1200px;
    margin: 0 auto;
    .grid__ {
      display: grid;
      grid-template-columns: 1.3fr 1fr;
      grid-gap: 2rem;
      @include md{
        grid-template-columns: 1fr;
      }
      .start {
        display: grid;
        align-items: center;
        padding-right: 20px;
      }
      .end {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
