@import "./media.scss";

.padding__ {
  padding: 0 70px;
  @include md {
    padding: 20px;
  }
}

.nav__logo__ {
  max-width: 65px;
  @include md {
    width: 90%;
  }
}
.main-header,
.main-header.sticky-menu {
  padding: 5px 0 5px !important;
}

.youtube {
  svg {
    transform: scale(1.5);
  }
}
.tiktok {
  svg {
    transform: scale(1.2);
  }
}

section#collection {
  position: relative;
}

section#collection::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../../assets/img/slider/slider_bg02.jpg);
  background-size: cover;
  z-index: 0;
  opacity: 0.6;
  filter: brightness(0.5) hue-rotate(291deg);
}
@include md{
.latest-games-active.slick .slick-arrow,
  .testimonial-active.slick .slick-arrow {
    position: absolute;
    right: 6px;
    top: -72px;
    z-index: 1;
  }
        .latest-games-active .slick-list {
          margin: 60px -15px;
        }
        .footer__span{
          display: flex;
          gap: 5px;
          text-align: center;
          justify-content: center;
        }
}

body{
  overflow-x: hidden;
}

html {
  width: 100vw;
 overflow-x: hidden;
}