@import "./media.scss";
.about__ {
  position: relative;
  background: #24252f;
  z-index: 1;
  &::before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    right: 0px;
    height: 213px;
    background: url(../../assets/images/1.png) no-repeat;
    opacity: 0.4;
  }
  &::after {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    background: url(../../assets/images/home_four_shop_bg.jpg) no-repeat;
    z-index: -1;
    opacity: 0.4;
    background-size: cover;
  }
  .container__ {
    .grid__ {
      @include md {
        grid-gap: 2rem;
      }
      .start__ {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }
      .about-btn {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 0px;
      }
      .end__ {
      }
    }
  }
}
